﻿/**
 *  @ngdoc controller
 *  @name hoa Directive
 *  @description HOA Directive
 */
(function () {
    "use:strict";

    angular.module("myApp").directive('hoaModel', function ($timeout) {
        return {
            controller: 'hoaController',
            restrict: 'E',
            scope: {
                modelId: "@",
                data: '='
            },
            templateUrl: 'app/directive/hoa/hoadir.html'
        }
    }).controller('hoaController', function ($scope, eyeResource) {
        
    });

}(window.angular));